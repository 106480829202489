import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

export default class MaskedInputController extends Controller {
  static targets = [ "maskedInput" ]

  connect(){
    this.maskedInputTargets.forEach(function (input, index) {
      new Cleave(input, {
        numeral: true,
        numeralDecimalScale: 0,
        numeralThousandsGroupStyle: 'thousand',
        delimiter: ' '
      });
    })
  }
}
