import { Controller } from "@hotwired/stimulus"
import * as tempusDominus from '@eonasdan/tempus-dominus/dist/js/tempus-dominus';
import * as plugin from "../fa-four"
tempusDominus.extend(plugin);
import * as moment_js_plugin from '@eonasdan/tempus-dominus/dist/plugins/moment-parse'
tempusDominus.extend(moment_js_plugin, 'DD.MM.yyyy');
import { localization } from '@eonasdan/tempus-dominus/dist/locales/ru';

export default class DatepickerController extends Controller {
  static targets = [ "source" ]

  connect(){
    this.sourceTargets.forEach(function (picker, index) {
      new tempusDominus.TempusDominus(picker, {
        localization: localization,
        display: {components: {clock: false}}
      })
    })
  }
}
