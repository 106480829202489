import { Controller } from "@hotwired/stimulus"
import Noty from "noty";

export default class PaySaleController extends Controller {
  static targets = [ 'cell', 'cell2' ]
  static classes = [ "pink" ]

  pay(event){
    this.cellTarget.innerHTML = new Date().toLocaleDateString('ru-RU')
    this.cellTarget.classList.remove(this.pinkClass)
  }

  clear(){
    this.cell2Target.innerHTML = ''
    new Noty({
      type: 'success',
      theme: 'bootstrap-v4',
      text: 'Оплата проставлена!'
    }).show();
  }
}
