// Entry point for the build script in your package.json
import './add_jquery'
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import Noty from "noty"
import * as plugin from "./fa-four"
import RandomPassword from "./random_password";

function generate_password() {
  var randomPassword4 = new RandomPassword();
  return randomPassword4.create(10, "")
}

document.addEventListener('turbo:load', () => {
  const nodes = document.querySelectorAll('.flash_msg');
  nodes.forEach(function (node, index) {
    new Noty({
      type: node.getAttribute('flash-type'),
      theme: 'bootstrap-v4',
      text: node.textContent
    }).show();
  });

  $("#refresh_key").click(function(){
    $("#antivirus_licence_key").val(generate_password());
    return false;
  });
})
