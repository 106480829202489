import daterangepicker from 'daterangepicker';

export function attach_date_range_picker(element, startDate, endDate) {
  element.daterangepicker({
    locale: {
      format: 'DD.MM.YYYY',
      startDate: startDate,
      endDate: endDate,
      applyLabel: 'Выбрать',
      cancelLabel: 'Отмена',
      fromLabel: 'Начало',
      toLabel: 'Конец',
      customRangeLabel: 'Диапазон дат',
      daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт','Сб'],
      monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      firstDay: 1
    },
    ranges: {
      'Сегодня': [moment(), moment()],
      'Вчера': [moment().subtract('days', 1), moment().subtract('days', 1)],
      'Последние 7 дней': [moment().subtract('days', 6), moment()],
      'Последние 30 дней': [moment().subtract('days', 29), moment()],
      'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
      'Прошлый месяц': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')],
      'Текущий год' : [moment().startOf('year'), moment().endOf('year')]
    },
    opens: 'right',
    alwaysShowCalendars: true
  });
}
