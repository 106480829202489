import { Controller } from "@hotwired/stimulus"
import '../add_jquery'
import select2 from 'select2'
select2($);

export default class SelectTwoController extends Controller {
  static targets = [ "select2" ]

  connect(){
    this.select2Targets.forEach(function (element, index) {
      $(element).select2({theme: 'bootstrap4'});
    })
  }

  disconnect() {
    this.select2Targets.forEach(function (element, index) {
      $(element).select2('destroy');
    })
  }
}
