import { Controller } from "@hotwired/stimulus"

export default class ParamsBypassController extends Controller {
  static targets = [ 'period' ]

  pdf(event){
    event.currentTarget.href +=`?period=${this.periodTarget.value}`
  }

  emails(event){
    event.currentTarget.href += `?period=${this.periodTarget.value}`
  }
}
