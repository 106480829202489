import { Controller } from "@hotwired/stimulus"
import { attach_date_range_picker } from "../date_range_picker";

export default class DateRangePickerController extends Controller {
  static targets = [ "dateRangePicker" ]

  connect(){
    this.dateRangePickerTargets.forEach(function (element, index) {
      const dates = element.value.split(' - ')
      const startDate = dates[0]
      const endDate = dates[1]
      attach_date_range_picker($(element), startDate, endDate);
    })
  }
}
